import apiProvider from "@/utils/api";
import axios, { AxiosPromise } from "axios";
import {toUrl} from "@/utils/helpers"
const rootPath = 'Files';


class FileService {
    public getTableUrl(idBop): string {
        return `${apiProvider.getUrl()}${rootPath}/dto${toUrl({idBop})}`;
    }

    public async addFile(file) {
        return await apiProvider.post(`${rootPath}`, file);
    }

    public async getContent(idFile) {
        return await apiProvider.get(`${rootPath}/file${toUrl({idFile})}`);
    }

    public async getAsImage(idFile) {
        return await apiProvider.get(`${rootPath}/fileAsImage${toUrl({ idFile })}`);
    }

    public async setContent(idFile, contentFile) {
        var myFile = {
            id: idFile,
            contentBase64: contentFile
        };
        return await apiProvider.post(`${rootPath}/updateContent`, myFile);
    }
}

export default new FileService();