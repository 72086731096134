



































    import Bop from "@/models/Bop";
    import File from "@/models/File";
    import { Component, Prop, Vue, Watch } from "vue-property-decorator";
    import FileService from "@/services/FileService";
    import BopService from "@/services/BopService";
    import toBase64 from "@/utils/file";
    import { Guid } from "guid-typescript";
    import Constants from "@/utils/Constants";
import { BopStatus } from "@/utils/Enums";

    @Component({
        components: {
            Table: () => import("@/components/Shared/Table/Table.vue"),
            OperationComponent: () =>
                import("@/components/BopSetup/shared/OperationComponent.vue"),
            ImageAnnotationModal: () =>
                import("@/components/BopSetup/ImageAnnotationModal.vue")
        },
    })
    export default class OperationPrincipalStepFile extends Vue {
        @Prop()
        private isEditable!: boolean;

        private modifiedFiles: Array<File> = [];
        private fileColumns: Array<Object> = [];
        private fileUrl: string = "";
        private currentImage: any = null;
        private modalProps: any = null;
        private isModalVisible: boolean = false;
        private file = null;
        @Watch("file")
        private async convert(newValue: any) {
            if (this.file) {
                if ((<any>this.$refs.table).updatedRows.length > 10) {
                    this.$notify({
                        group: "globalError",
                        type: "error",
                        title: this.$t("bop.errors.title").toString(),
                        text: this.$t('bop.errors.moreThan10Files').toString(),
                        duration: Constants.DEFAULT_WAIT_ERROR_NOTIFICATIONS,
                    });
                } else if (Guid.parse(this.bop.id).isEmpty()) {
                    this.$notify({
                        group: "globalError",
                        type: "error",
                        title: this.$t("file.addError.title").toString(),
                        text: this.$t("file.addError.bopMustBeCreatedBeforeAddingFile").toString(),
                        duration: Constants.DEFAULT_WAIT_ERROR_NOTIFICATIONS,
                    });
                } else {
                    const newFile = await toBase64(this.file);
                    //(<any>this.$refs.table).updatedRows.push(newFile);
                    //(<any>this.$refs.table).modify(newFile);
                    try {
                        (<any>newFile).bopId = this.bop.id;
                        // console.log({newFile});
                        await FileService.addFile(newFile);
                        await (<any>this.$refs.table).refreshAPI();
                        this.updatebopFiles();

                        this.$notify({
                            group: "global",
                            type: "success",
                            title: this.$t("file.addSuccess.title").toString(),
                            text: this.$t("file.addSuccess.description").toString(),
                        });
                    } catch (err) {
                        this.$notify({
                            group: "globalError",
                            type: "error",
                            title: this.$t("file.addError.title").toString(),
                            text: this.$t(err?.response?.data?.detail).toString() || this.$t("fileExceedsMaxSize").toString(),
                            duration: Constants.DEFAULT_WAIT_ERROR_NOTIFICATIONS,
                        });
                    }
                }
            }
        }

        @Watch("modifiedFiles")
        private update(newValue: any) {
            this.updatebopFiles();
        }

        private get isEdit(): boolean {
            return (
            this.$route.query.isEdit !== undefined &&
            this.$route.query.isEdit === "true"
            );
        }

        private get computedEditable(): boolean {
            return (
                (!this.bop.isCreatedBySap && this.bop.bopStatusKey === BopStatus.AcceptLunchWork)
                || (this.bop.bopStatusKey === BopStatus.Finished && this.isEdit)
                || this.isEditable
            );
        }

        private async validate(annotateImage, imageId) {
            this.isModalVisible = false;
            FileService.setContent(imageId, annotateImage).then(r => {
                if (r.status == 200) {
                    this.$notify({
                        group: "global",
                        type: "success",
                        title: this.$t("file.updateSuccess.title").toString(),
                        text: this.$t("file.updateSuccess.description").toString(),
                    });
                }
            }).catch(err => {
                this.$notify({
                    group: "global",
                    type: "error",
                    title: this.$t("file.updateError.title").toString(),
                    text: this.$t("file.updateError.description").toString(),
                });
            })

        }

        private async download(props: any) {
            const content = await FileService.getContent(props.id);
            const downloadLink = document.createElement("a");
            //console.log(content.data)
            downloadLink.href = content.data;
            downloadLink.download = props.name;
            downloadLink.click();
        }

        private async openModal(props: any) {
            this.currentImage = props;
            let fileToAnnotate: any;
            fileToAnnotate = await FileService.getAsImage(props.id);
            this.modalProps = fileToAnnotate.data;
            this.isModalVisible = true;
        }

        private get bop() {
            return this.$store.state.bop;
        }
        private set bop(value: Bop) {
            this.$store.commit("changeBop", value);
        }

        private async remove(props: any) {
            const index = (<any>this.$refs.table).updatedRows.findIndex(file => file.id == props.rowData.id);
            if (index > -1) (<any>this.$refs.table).updatedRows.splice(index, 1);
            this.updatebopFiles();

            const response = await BopService.save(this.bop);
            if (response.status === 200) {
                this.$notify({
                    group: "global",
                    type: "success",
                    title: this.$t("file.removeSuccess.title").toString(),
                    text: this.$t("file.removeSuccess.description").toString(),
                });
            } else {
                this.$notify({
                    group: "global",
                    type: "error",
                    title: this.$t("file.removeError.title").toString(),
                    text: this.$t("file.removeError.description").toString(),
                });
            }
        }

        private updatebopFiles() {
            this.bop.files = (<any>this.$refs.table).updatedRows;
            //console.log((<any>this.$refs.table).updatedRows)
        }

        public mounted() {
            this.fileUrl = FileService.getTableUrl(this.bop.id);

           
            // console.log("parent : " + this.$parent);

            this.fileColumns = [
                {
                    name: "editable",
                    title: this.$t("bopSetup.operationPrincipal.file.name").toString(),
                    property: "name",
                },
                {
                    name: "editable",
                    title: this.$t("bopSetup.operationPrincipal.file.extension").toString(),
                    property: "extension",
                    isNotEditable: true,
                },
                {
                    name: "actions",
                    title: "",
                    removeButton: this.computedEditable,
                    editButton: this.computedEditable,
                    downloadButton: true,
                    duplicateButton: false,
                }
            ];
        }
    }
