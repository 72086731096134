import File from "@/models/File";

function toBase64(file) : Promise<File> {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
            let newFile = new File();
            newFile.name = file.name;
            newFile.extension = file.type;
            (<any>newFile).content = reader.result;
            return resolve(newFile);
        };
        reader.onerror = error => reject(error);
    })
};

export default toBase64;