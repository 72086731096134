import { Guid } from "guid-typescript";


export default class File {
    public id: string;
    public name: string;
    public extension: string;
    //public content: string;


    constructor() {
        this.id = Guid.createEmpty().toString();
        this.name = "";
        this.extension = "";
        //this.content = "";
    }
}
